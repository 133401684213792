import { Notification } from "../components/Common/AntdComponents";
import {
  UPLOAD_IMAGE,
  UPLOAD_IMAGE_FAILURE,
  UPLOAD_IMAGE_SUCCESS,
} from "../utils/constants/actionTypes";

import { postData, put } from "../utils/httpUtil";

export const updateUser = (params) => {
  return put(`user/update`, params, "application/json")
    .then((res) => {
      Notification(
        "Success!",
        `User has been updated successfully!`,
        "success"
      );
    })
    .catch((err) => {
      Notification("Error!", "Something went wrong!", "error");
    });
};

export const uploadProfileImage = (params) => {
  return postData(
    UPLOAD_IMAGE,
    UPLOAD_IMAGE_SUCCESS,
    UPLOAD_IMAGE_FAILURE,
    `users/profile-image`,
    params,
    "multipart/form-data"
  );
};
