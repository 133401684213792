import {
  GET_ALL_SERVICES,
  GET_ALL_SERVICES_SUCCESS,
  GET_ALL_SERVICES_FAILURE,
} from "../utils/constants/actionTypes";

const INITIAL_STATE = {
  payload: [],
  loading: false,
  errors: {},
};

/**
 * A reducer takes two arguments, the current state and an action.
 */
export const serviceReducer = (state, action) => {
  state = state || INITIAL_STATE;
  switch (action?.type) {
    case GET_ALL_SERVICES:
      return Object.assign({}, state, {
        loading: true,
      });
    case GET_ALL_SERVICES_SUCCESS:
      return Object.assign({}, state, {
        payload: action.data,
        loading: false,
        errors: {},
      });

    case GET_ALL_SERVICES_FAILURE:
      return Object.assign({}, state, {
        payload: [],
        loading: false,
        errors: action.error,
      });
    default:
      return state;
  }
};
