import React, { useEffect, useState, useContext } from "react";
import { Layout, Spin } from "antd";
import { Link, useLocation } from "react-router-dom";
import { RightOutlined, LeftOutlined } from "@ant-design/icons";

import NavMenu from "./NavMenuItem";
import { useSize } from "../../../windowSize";
import logo from "../../../assets/images/logo.png";
import navMenuList from "../../../utils/constants/navMenu";
import { AuthContext } from "../../Context/AuthContext";
import LogoutIcon from "../../../assets/images/icon-image/LogoutIcon.svg";

const { Sider } = Layout;

const SideNav = React.memo((props) => {
  const { collapsed, setCollapsed } = props;
  const { navLoading, allowedMenuList, logout } = useContext(AuthContext);
  const { innerWidth } = useSize();
  const [isSideMenuHover, setIsSideMenuHover] = useState(false);
  const [logoSrc, setLogoSrc] = useState(null);
  const [open, setOpen] = useState(false);

  const pathname = useLocation().pathname;
  const initialActive = pathname
    .split("/")
    .filter((item, index) => index < 3)
    ?.join("/");
  const [active, setActive] = useState(initialActive);

  const fLogo = () => (
    <div className="logo-container a-c">
      <img className="flogo" src={logo} alt={"logo1"} />
    </div>
  );

  useEffect(() => {
    setLogoSrc(fLogo());
  }, []);

  return (
    <Sider
      className={"sider"}
      style={
        innerWidth > 685
          ? { background: "white" }
          : { background: "white", position: "fixed", zIndex: "100" }
      }
      trigger={null}
      width={236}
      collapsible
      collapsed={collapsed}
    >
      <Link to="/home" className="logo-img">
        {collapsed ? null : logoSrc}
      </Link>
      <div
        onMouseEnter={() => {
          setIsSideMenuHover(true);
        }}
        onMouseLeave={() => {
          setIsSideMenuHover(false);
        }}
        className="nav-menu-wrapper"
      >
        <div className="nav-logout-wrapper" onClick={logout}>
          <span className={`logout-text`}>
            <img
              style={{
                marginRight: "10px",
                float: "left",
                height: "27px",
                width: "27px",
              }}
              src={LogoutIcon}
              alt={"sub-menu"}
            />
            Logout
          </span>
        </div>
        {!navLoading ? (
          navMenuList?.map((menuItem, index) => {
            return (
              <div
                onClick={() => {
                  if (innerWidth < 685) setCollapsed(true);
                }}
                key={index}
              >
                <NavMenu
                  menu={menuItem}
                  {...menuItem}
                  {...props}
                  active={active}
                  setActive={setActive}
                  collapsed={collapsed}
                  open={open}
                  setOpen={setOpen}
                  allowedMenuList={allowedMenuList}
                  logout={logout}
                />
              </div>
            );
          })
        ) : (
          <div className="centralize h-full">
            <Spin size="large" />
          </div>
        )}
      </div>
    </Sider>
  );
});

export default SideNav;
