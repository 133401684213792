import {
  GET_TASK,
  GET_TASK_SUCCESS,
  GET_TASK_FAILURE,
  GET_CATEGORIES,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_FAILURE,
  GET_PROJECTS,
  GET_PROJECTS_SUCCESS,
  GET_PROJECTS_FAILURE,
} from "../utils/constants/actionTypes";

const INITIAL_STATE = {
  payload: [],
  loading: false,
  errors: {},
};

const PROJECT_INITIAL_STATE = {
  payload: [],
  loading: false,
  errors: {},
};

const CATEGORIES_INITIAL_STATE = {
  payload: [],
  loading: false,
  errors: {},
};

/**
 * A reducer takes two arguments, the current state and an action.
 */
export const getTaskReducer = (state, action) => {
  state = state || INITIAL_STATE;
  switch (action?.type) {
    case GET_TASK:
      return Object.assign({}, state, {
        loading: true,
      });
    case GET_TASK_SUCCESS:
      return Object.assign({}, state, {
        payload: action.data,
        loading: false,
        errors: {},
      });

    case GET_TASK_FAILURE:
      return Object.assign({}, state, {
        payload: [],
        loading: false,
        errors: action.error,
      });
    default:
      return state;
  }
};

export const getProjectReducer = (state, action) => {
  state = state || PROJECT_INITIAL_STATE;
  switch (action?.type) {
    case GET_PROJECTS:
      return Object.assign({}, state, {
        loading: true,
      });
    case GET_PROJECTS_SUCCESS:
      return Object.assign({}, state, {
        payload: action.data,
        loading: false,
        errors: {},
      });

    case GET_PROJECTS_FAILURE:
      return Object.assign({}, state, {
        payload: [],
        loading: false,
        errors: action.error,
      });
    default:
      return state;
  }
};

export const getCategoriesReducer = (state, action) => {
  state = state || CATEGORIES_INITIAL_STATE;
  switch (action?.type) {
    case GET_CATEGORIES:
      return Object.assign({}, state, {
        loading: true,
      });
    case GET_CATEGORIES_SUCCESS:
      return Object.assign({}, state, {
        payload: action.data,
        loading: false,
        errors: {},
      });

    case GET_CATEGORIES_FAILURE:
      return Object.assign({}, state, {
        payload: [],
        loading: false,
        errors: action.error,
      });
    default:
      return state;
  }
};
