import axios from "axios";
import {
  GENESIS_USER_EMAIL,
  GENESIS_USER_TOKEN,
} from "../utils/constants/appConfigs";
import { loadState } from "../utils/storageUtil";

export const fetchUserInfo = async () => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}/users/profile`,
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
          Accept: "application/json",
          Authorization: `Bearer ${loadState(GENESIS_USER_TOKEN)}`,
        },
      }
    );

    return res;
  } catch (err) {
    throw err?.response;
  }
};

export const createPaymentIntent = async (params) => {
  try {
    const res = await axios({
      url: `${process.env.REACT_APP_API_ENDPOINT}/stripe/create-payment-intent`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${loadState(GENESIS_USER_TOKEN)}`,
      },
      data: params,
    });
    return res?.data;
  } catch (err) {
    console.log("err", err);
  }
};

export const createSubscriptionFromServer = async (params) => {
  try {
    const response = await axios({
      url: `${process.env.REACT_APP_API_ENDPOINT}/stripe/create-subscription`,

      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${loadState(GENESIS_USER_TOKEN)}`,
      },
      data: params,
    });
    return response?.data;
  } catch (err) {
    console.log("err", err);
  }
};
