import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { Popover } from "antd";

const NavMenuItem = (props) => {
  const [toggleOpen, setToggleOpen] = useState(true);

  let pathname = useLocation().pathname;
  useEffect(() => {
    if (!props.subMenu) {
      if (pathname.includes(props.id)) {
        props.setActive(props.id);
      }
    } else {
      props.subMenu.forEach((sub) => {
        if (pathname.includes(sub.id)) {
          props.setActive(sub.id);
          if (toggleOpen) {
            props.setOpen(props.id);
          }
        }
      });
    }
  }, [pathname, props, toggleOpen]);

  const PopeoverWrapper = (localProps) => {
    return props.collapsed ? (
      <Popover content={localProps.content} placement="right">
        {localProps.children}
      </Popover>
    ) : (
      localProps.children
    );
  };

  const isAllowed = (menu) => {
    if (menu?.subMenu) {
      let temp = menu.subMenu
        .map((e) => props.allowedMenuList.includes(e.right))
        .filter((item) => item);
      return temp.length > 0;
    } else {
      return (
        props.allowedMenuList?.includes(menu.right) ||
        menu.label === "Dashboard" ||
        menu.label === "Event/Task" ||
        menu.label === "Social & Streaming" ||
        menu.label === "Badges" ||
        menu.label === "Goal" ||
        menu.label === "Settings" ||
        menu.label === "Services" ||
        menu.label === "Booking History" ||
        menu.label === "Logout"
      );
    }
  };

  return isAllowed(props.menu) ? (
    <div>
      <Link to={props.to}>
        <PopeoverWrapper
          content={<b style={{ color: "#006595" }}>{props.label}</b>}
        >
          <div
            onClick={() => {
              console.log("props logout", props.label);
              if (!props.subMenu) {
                props.setOpen(false);
                props.setActive(props.id);
              } else if (props?.label === "Logout") {
                props.logout();
              } else {
                if (props.open === props.id) {
                  props.setOpen(false);
                  setToggleOpen(false);
                } else {
                  setToggleOpen(true);
                  props.setOpen(props.id);
                }
              }
            }}
            className={`a-c nav-menu-item ${
              props.active === props.id ? "active" : ""
            } ${props.collapsed ? "collapsed-menu" : ""}`}
          >
            <span className="nav-menu-label">
              {props.icon instanceof Function ? (
                props.active !== props.id ? (
                  props.icon()
                ) : (
                  props.activeIcon()
                )
              ) : (
                <img
                  src={
                    props.active !== props.id ? props.icon : props.activeIcon
                  }
                  className={"nav-menu-image"}
                  alt={"menu"}
                />
              )}
              {!props.collapsed && props.label}{" "}
              {!props.collapsed && props.subMenu && (
                <>
                  {props.open !== props.id ? (
                    <DownOutlined
                      style={{ position: "absolute", right: "20px" }}
                    />
                  ) : (
                    <UpOutlined
                      style={{ position: "absolute", right: "20px" }}
                    />
                  )}
                </>
              )}
            </span>
          </div>
        </PopeoverWrapper>
      </Link>
      {props.subMenu &&
        props.open === props.id &&
        props.subMenu?.map((sub) => {
          return (
            isAllowed(sub) && (
              <Link key={sub.id} to={sub.to || "#"}>
                <PopeoverWrapper
                  content={<b style={{ color: "#006595" }}>{sub.label}</b>}
                >
                  <div
                    onClick={() => {
                      props.setActive(sub.id);
                    }}
                    className={`a-c nav-menu-item ${
                      props.active === sub.id ? "active" : ""
                    } ${props.collapsed ? "collapsed-menu" : ""} sub`}
                  >
                    <span
                      className={`nav-menu-label sub-label ${
                        props.collapsed ? "collapsed-sub-label" : ""
                      }`}
                    >
                      <img
                        style={{
                          marginRight: "10px",
                          float: "left",
                          height: "27px",
                          width: "27px",
                        }}
                        src={
                          props.active !== sub.id ? sub.icon : sub.activeIcon
                        }
                        alt={"sub-menu"}
                      />
                      {!props.collapsed && sub.label}
                    </span>
                  </div>
                </PopeoverWrapper>
              </Link>
            )
          );
        })}
    </div>
  ) : null;
};

export default NavMenuItem;
