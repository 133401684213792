import React from "react";
import { Spin } from "antd";

const Loader = ({ center = true }) => {
  if (center) {
    return (
      <div style={{ transform: "translate(50%, 600%)" }}>
        <Spin size="large" />
      </div>
    );
  }
  return <Spin tip="Loading..." size="large" />;
};

export default Loader;
