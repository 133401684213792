import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import configureStore from "./store/configureStore";
import { ConnectedRouter } from "connected-react-router";
import { GoogleOAuthProvider } from "@react-oauth/google";

// import "antd/dist/antd.css";
import "antd/dist/antd.min.css";

import "./index.css";
import "./styles/normalize.css";
import "./assets/css/icons.css";
import App from "./pages/App";
import history from "./utils/history";

import * as serviceWorker from "./serviceWorker";
import AuthProvider from "./components/Context/AuthContext";
import { Route, Switch } from "react-router-dom/cjs/react-router-dom.min";
import Callback from "./pages/Callback/Callback";
import moment from "moment";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const mountNode = document.getElementById("root");
const store = configureStore();
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

ReactDOM.render(
  <Elements stripe={stripePromise}>
    <GoogleOAuthProvider clientId="706189537956-tcg29ts6mm7dknnvn9s6a6rjai5995ht.apps.googleusercontent.com">
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Router basename={process.env.PUBLIC_URL} history={history}>
            <AuthProvider>
              <App />
            </AuthProvider>
          </Router>
        </ConnectedRouter>
      </Provider>
    </GoogleOAuthProvider>
  </Elements>,
  mountNode
);

serviceWorker.unregister();
