import axios from "axios";

import { loadState } from "./storageUtil";
import { GENESIS_USER_TOKEN } from "../utils/constants/appConfigs";
import { isEmpty } from "./commonUtils";

const BASE_URL = process.env.REACT_APP_API_ENDPOINT;

/**
 * Configure Serialization and request header with axios.
 *
 * @param {String} contentType
 * @param {object} params
 * @param {boolean} textDetection
 * @returns {promise}
 */
export const Api = (
  contentType = "application/x-www-form-urlencoded;charset=utf-8",
  params = {},
  googleToken
) => {
  const header = loadState(GENESIS_USER_TOKEN)
    ? {
        "Content-Type": contentType,
        Accept: "application/json",
        Authorization: `Bearer ${loadState(GENESIS_USER_TOKEN)}`,
      }
    : googleToken
    ? {
        "Content-Type": contentType,
        Accept: "application/json",
        Authorization: `Bearer ${googleToken}`,
      }
    : {
        "Content-Type": contentType,
        Accept: "application/json",
      };
  const config = !isEmpty(params)
    ? {
        paramsSerializer: (params) => {
          let result = "";
          Object.keys(params).forEach((key) => {
            if (params[key] === undefined || params[key] === null) {
              result += `${key}=&`;
            } else {
              result += `${key}=${String(params[key]).split(" ").join("+")}&`;
            }
          });
          return result.substr(0, result.length - 1);
        },
        baseURL: `${BASE_URL}`,
        headers: header,
        responseType: "json",
      }
    : { baseURL: `${BASE_URL}`, headers: header, responseType: "json" };
  const api = axios.create(config);

  api.interceptors.response.use(
    (response) => {
      return response;
    },
    (error, status) => {
      if (401 === error) {
        return Promise.reject(error?.response?.statusText);
      }
      if (404 === error) {
        return Promise.reject(error?.response?.statusText);
      }
      if (409 === error?.response?.status) {
        return Promise.reject(error?.response);
      }
      if (500 === error?.response?.status) {
        return Promise.reject(error?.response?.statusText);
      }

      return Promise.reject(error?.response?.statusText);
    }
  );

  return api;
};
