/**
 * returns true if received argument is object and has no properties,
 * if the received argument is array and the array length is 0,
 * if the received argument is a falsey value
 * @param {any} obj
 * @returns {boolean}
 */
export const isEmpty = (obj) => {
  if (typeof obj === "number") {
    return false;
  }
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
};

/**
 * Returns true if the received argument is pure object
 * @param {any} obj
 * @returns {boolean}
 */
export const isObject = (obj) => {
  if (obj instanceof Object && !(obj instanceof Array)) {
    return true;
  }
  return false;
};

/**
 * Returns true if the received argument is array
 * @param {any} obj
 * @returns {boolean}
 */
export const isArray = (obj) => {
  if (obj instanceof Array) {
    return true;
  }
  return false;
};

/**
 * This function is used for masking the field that is take in
 * phone numbers or mobile number.
 * @param {number} number
 * @returns
 */
export const numberMask = (number) => {
  if (number) {
    let cv = -1;
    cv = number.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    return !cv[2]
      ? cv[1]
      : "(" + cv[1] + ") " + cv[2] + (cv[3] ? "-" + cv[3] : "");
  } else {
    return undefined;
  }
};

/**
 * This function is used to handle search in list pages where
 * the api the api that is used to fetch list does have searchQuearyParams.
 * @param {object} form
 * @param {array} mainList
 * @param {setState callback of the useState hook} setList
 */
export const listSearch = (form, mainList, setList) => {
  let filteredData = [];
  const temp = [];
  for (const key in form.getFieldsValue()) {
    if (form.getFieldsValue()[key]) {
      const tempFilteredData = !isEmpty(filteredData) ? filteredData : mainList;
      filteredData = tempFilteredData.filter((item) => {
        if (
          typeof form.getFieldValue(key) === "string" &&
          isNaN(Number(form.getFieldValue(key)))
        ) {
          return item[key]
            ?.toLowerCase()
            ?.includes(form.getFieldValue(key).toLowerCase());
        }
        return item[key] === form.getFieldValue(key);
      });
      temp.push(true);
    } else {
      temp.push(false);
    }
  }

  setList(filteredData);
  if (!temp.includes(true)) {
    setList(mainList);
  }
};
