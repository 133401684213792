import {
  SIGN_IN,
  SIGN_IN_SUCCESS,
  SIGN_IN_FAILURE,
  SIGN_OUT,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
} from "../utils/constants/actionTypes";

const INITIAL_STATE = {
  payload: [],
  loading: false,
  errors: {},
  pagination: {},
};

const FORGOT_PASSWORD_INITIAL_STATE = {
  payload: [],
  loading: false,
  errors: {},
};

/**
 * A reducer takes two arguments, the current state and an action.
 */
export const signInReducer = (state, action) => {
  state = state || INITIAL_STATE;
  switch (action?.type) {
    case SIGN_IN:
      return Object.assign({}, state, {
        loading: true,
      });
    case SIGN_IN_SUCCESS:
      return Object.assign({}, state, {
        payload: action.data,
        loading: false,
        errors: {},
      });

    case SIGN_IN_FAILURE:
      return Object.assign({}, state, {
        payload: [],
        loading: false,
        errors: action.error,
      });
    case SIGN_OUT:
      return Object.assign({}, state, {
        payload: [],
      });
    default:
      return state;
  }
};

export const forgotPasswordReducer = (state, action) => {
  state = state || FORGOT_PASSWORD_INITIAL_STATE;
  switch (action?.type) {
    case FORGOT_PASSWORD:
      return Object.assign({}, state, {
        loading: true,
      });
    case FORGOT_PASSWORD_SUCCESS:
      return Object.assign({}, state, {
        payload: action.data,
        loading: false,
        errors: {},
      });

    case FORGOT_PASSWORD_FAILURE:
      return Object.assign({}, state, {
        payload: [],
        loading: false,
        errors: action.error,
      });

    default:
      return state;
  }
};
