import {
  REDUCER_CLEAN_REQUEST,
  PACKAGE_CLEAN_REQUEST,
} from "../utils/constants/actionTypes";

export const reducerCleanRequest = () => {
  return {
    type: REDUCER_CLEAN_REQUEST,
  };
};

export const packageCleanRequest = () => {
  return {
    type: PACKAGE_CLEAN_REQUEST,
  };
};

export const headingRequest = (data) => {
  return {
    type: "HEADING",
    data,
  };
};
