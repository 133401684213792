import React, { useContext, useState } from "react";
import ReactPlayer from "react-player";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Avatar,
  Menu,
  Dropdown,
  Layout,
  Popover,
  Typography,
} from "antd";

import { AuthContext } from "../../Context/AuthContext";
import { useSize } from "../../../windowSize";
import { QuestionCircleFilled } from "@ant-design/icons";
import NotificationIcon from "../../../assets/images/icon-image/NotificationIcon.svg";
import BadgeIcon from "../../../assets/images/icon-image/Badges.svg";
import AvatarImage from "../../../assets/images/Avatar.png";

const { Header } = Layout;

const AppHeader = ({ navCollapsed }) => {
  const { innerWidth } = useSize();
  const { logout, user } = useContext(AuthContext);

  const history = useHistory();

  const showName = () => {
    if (navCollapsed) {
      if (innerWidth > 640) {
        return true;
      }
    } else {
      if (innerWidth > 725) {
        return true;
      }
    }
    return false;
  };

  const menu = (
    <Menu className={"dropdown-menu"}>
      {innerWidth <= 640 && (
        <>
          <div
            style={{
              height: 25,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: 15,
              borderBottom: "1px solid black",
            }}
          >
            <b>{user?.data?.emailAddress}</b>
          </div>
        </>
      )}
      <Menu.Item
        onClick={() => {
          history.push("/h/account-settings");
        }}
        className={"dropdown-item"}
      >
        Account settings
      </Menu.Item>

      <Menu.Item className={"dropdown-item"} onClick={logout}>
        Sign Out
      </Menu.Item>
    </Menu>
  );

  return (
    <Header className={"header-container"}>
      <Row align="middle">
        <Col xxl={16} xl={14} lg={14} md={12} sm={10} xs={10}></Col>
        <Col xxl={8} xl={10} lg={10} md={12} sm={14} xs={14}>
          {showName() && (
            <Row
              align="middle"
              justify="center"
              className="header-right-container"
            >
              <Row className="level-container">
                <img src={BadgeIcon} />
                <Typography className="header-level-text">Level 1</Typography>
              </Row>
              <img src={NotificationIcon} />
              <Row>
                <img
                  className="profile-image"
                  src={
                    user?.data?.profileImageURL
                      ? user?.data?.profileImageURL
                      : AvatarImage
                  }
                />
                <Col className="text-container">
                  <Typography className="header-name-text">
                    {user?.data?.fullName}
                  </Typography>
                  <Typography className="header-email-text">
                    {user?.data?.emailAddress}
                  </Typography>
                </Col>
              </Row>
            </Row>
          )}
        </Col>
      </Row>
    </Header>
  );
};

const mapStateToProps = (state) => ({
  // heading: state.heading.payload,
});

export default connect(mapStateToProps)(AppHeader);
