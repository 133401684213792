import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import history from "../utils/history";
import { forgotPasswordReducer, signInReducer } from "./authReducer";
import {
  getCategoriesReducer,
  getProjectReducer,
  getTaskReducer,
} from "./taskReducer";
import { getEventReducer } from "./eventReducer";
import { dashboardReducer } from "./dashboardReducer";
import { serviceReducer } from "./serviceReducer";
import { professionalListByServiceIdReducer } from "./professionalReducer";
import {
  bookingSlotsListByProfessinaIdReducer,
  getBookingHistpryReducer,
  postBookingByProfessinaIdReducer,
} from "./bookingSlotReducer";

/**
 * combine all the reducers here to create state that will be
 * available all the components
 */
const appReducer = combineReducers({
  router: connectRouter(history),
  currentUser: signInReducer,
  task: getTaskReducer,
  event: getEventReducer,
  dashboard: dashboardReducer,
  services: serviceReducer,
  professionalListByServiceId: professionalListByServiceIdReducer,
  availableSlots: bookingSlotsListByProfessinaIdReducer,
  postBooking: postBookingByProfessinaIdReducer,
  getBookingHistory: getBookingHistpryReducer,
  forgotPassword: forgotPasswordReducer,
  getCategories: getCategoriesReducer,
  projectList: getProjectReducer,
});

const rootReducer = (state, action) => {
  if (action && action.type === "SIGN_OUT") {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
