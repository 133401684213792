import {
  GET_BOOKING_HISTORY,
  GET_BOOKING_HISTORY_FAILURE,
  GET_BOOKING_HISTORY_SUCCESS,
  GET_BOOKING_SLOTS_BY_SERVICE_ID,
  GET_BOOKING_SLOTS_BY_SERVICE_ID_FAILURE,
  GET_BOOKING_SLOTS_BY_SERVICE_ID_SUCCESS,
  POST_BOOKING_SLOTS_BY_SERVICE_ID,
  POST_BOOKING_SLOTS_BY_SERVICE_ID_FAILURE,
  POST_BOOKING_SLOTS_BY_SERVICE_ID_SUCCESS,
} from "../utils/constants/actionTypes";

const INITIAL_STATE = {
  payload: [],
  loading: false,
  errors: {},
};

const CREATE_INITIAL_STATE = {
  payload: [],
  loading: false,
  errors: {},
};

const HISTORY_INITIAL_STATE = {
  payload: [],
  loading: false,
  errors: {},
};
/**
 * A reducer takes two arguments, the current state and an action.
 */
export const bookingSlotsListByProfessinaIdReducer = (state, action) => {
  state = state || INITIAL_STATE;
  switch (action?.type) {
    case GET_BOOKING_SLOTS_BY_SERVICE_ID:
      return Object.assign({}, state, {
        loading: true,
      });
    case GET_BOOKING_SLOTS_BY_SERVICE_ID_SUCCESS:
      return Object.assign({}, state, {
        payload: action.data,
        loading: false,
        errors: {},
      });

    case GET_BOOKING_SLOTS_BY_SERVICE_ID_FAILURE:
      return Object.assign({}, state, {
        payload: [],
        loading: false,
        errors: action.error,
      });
    default:
      return state;
  }
};

export const postBookingByProfessinaIdReducer = (state, action) => {
  state = state || CREATE_INITIAL_STATE;
  switch (action?.type) {
    case POST_BOOKING_SLOTS_BY_SERVICE_ID:
      return Object.assign({}, state, {
        loading: true,
      });
    case POST_BOOKING_SLOTS_BY_SERVICE_ID_SUCCESS:
      return Object.assign({}, state, {
        payload: action.data,
        loading: false,
        errors: {},
      });

    case POST_BOOKING_SLOTS_BY_SERVICE_ID_FAILURE:
      return Object.assign({}, state, {
        payload: [],
        loading: false,
        errors: action.error,
      });
    default:
      return state;
  }
};

export const getBookingHistpryReducer = (state, action) => {
  state = state || HISTORY_INITIAL_STATE;
  switch (action?.type) {
    case GET_BOOKING_HISTORY:
      return Object.assign({}, state, {
        loading: true,
      });
    case GET_BOOKING_HISTORY_SUCCESS:
      return Object.assign({}, state, {
        payload: action.data,
        loading: false,
        errors: {},
      });

    case GET_BOOKING_HISTORY_FAILURE:
      return Object.assign({}, state, {
        payload: [],
        loading: false,
        errors: action.error,
      });
    default:
      return state;
  }
};
