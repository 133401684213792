import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import InputMask from "react-input-mask";
import "react-datepicker/dist/react-datepicker.css";
import {
  Input,
  Button,
  Form,
  AutoComplete,
  notification,
  Select,
  Checkbox,
  Spin,
  Modal,
  Skeleton,
  DatePicker,
  TimePicker,
} from "antd";
import {
  SearchOutlined,
  LoadingOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  MailOutlined,
  LockOutlined,
  PaperClipOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  UserOutlined,
  ArrowLeftOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import CardIcon from "../../../assets/images/icon-image/CardIcon.svg";

import { isArray, isEmpty } from "../../../utils/commonUtils";
import moment from "moment";

const { Option } = Select;
const { confirm } = Modal;

export const FormItem = ({
  name,
  label,
  colon,
  children,
  formStyle,
  localRules,
  formItemProps,
  formItemLayout,
  hideLabel,
  overflowHidden,
  initialValue,
  shouldUpdate,
  viewDetails,
  textArea,
  valuePropName,
  hidden,
  labelStyle,
}) => {
  const localFormStyle = formStyle || {};
  const hiddenStyle = hidden ? { height: 0, width: 0, margin: 0 } : {};
  const localStyle = overflowHidden
    ? { overflowX: "hidden", overflowY: "hidden", ...localFormStyle }
    : { ...localFormStyle };
  return (
    <Form.Item
      name={name}
      label={
        hideLabel || hidden ? null : (
          <span
            style={{
              fontSize: "12px",
              fontFamily: "Poppins",
              fontWeight: "500",
              color: "#5E656B",
              lineHeight: "21px",
            }}
          >
            {label}
          </span>
        )
      }
      valuePropName={valuePropName}
      validateFirst
      shouldUpdate={shouldUpdate}
      initialValue={
        initialValue ||
        typeof initialValue === "boolean" ||
        initialValue === "" ||
        initialValue === 0
          ? initialValue
          : null
      }
      style={{ marginBottom: 10, ...localStyle, ...hiddenStyle }}
      {...formItemProps}
      rules={localRules}
      {...formItemLayout}
      colon={colon || false}
      className={viewDetails && !textArea ? "detail-view" : ""}
    >
      {children}
    </Form.Item>
  );
};

const AntInput = React.forwardRef((props, ref) => {
  const {
    type,
    style,
    rules,
    label,
    onBlur,
    search,
    onClick,
    loading,
    hidden,
    message,
    disabled,
    onSearch,
    onChange,
    required,
    className,
    fieldProps,
    allowClear,
    placeholder,
    labelStyle,
  } = props;

  const handleWhiteSpaceValidation = (rules, value) => {
    if (value && typeof value !== "number") {
      if (value?.split("")?.[0] !== " ") {
        return Promise.resolve();
      } else {
        return Promise.reject(`${label} cannot start with space`);
      }
    } else {
      return Promise.resolve();
    }
  };

  const handleNumberValidation = (rules, value) => {
    if (value) {
      if (!isNaN(Number(value))) {
        return Promise.resolve();
      } else {
        return Promise.reject(`${label} should be number`);
      }
    } else {
      return Promise.resolve();
    }
  };

  const positiveNumberValidation = (rules, value) => {
    if (value) {
      if (Number(value) >= 0) {
        return Promise.resolve();
      } else {
        return Promise.reject(`${label} should be positive`);
      }
    } else {
      return Promise.resolve();
    }
  };

  const numberAndCharacterValidation = (rules, value) => {
    if (value) {
      let pattern = /^[a-zA-Z][a-zA-Z0-9 ]*$/;
      if (pattern.test(value)) {
        return Promise.resolve();
      } else {
        return Promise.reject(`Invalid ${label}`);
      }
    } else {
      return Promise.resolve();
    }
  };

  const percentageValidation = (rules, value) => {
    if (value) {
      if (Number(value) <= 100 && Number(value) >= 0) {
        if (value.includes(".")) {
          const decimalPlaces = value.split(".")[1]?.length;
          if (decimalPlaces <= 6) {
            return Promise.resolve();
          } else {
            return Promise.reject("Upto 6 decimal place allowed");
          }
        } else {
          return Promise.resolve();
        }
      } else {
        return Promise.reject(`Value between 0-100`);
      }
    } else {
      return Promise.resolve();
    }
  };

  const emailPattern = {
    pattern: new RegExp("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z0-9-.]+$"),
    // message: "Please enter in example@email.com format",
    message: "Please enter  valid email ",
  };

  const webPattern = {
    pattern: new RegExp(
      /^(https?:\/\/)?((([a-z\d]([a-z\d-]*[a-z\d])*)\.)+[a-z]{2,}|((\d{1,3}\.){3}\d{1,3}))(:\d+)?$/i
    ),
    message: "Please enter valid website url.",
  };

  const whiteSpacePattern = {
    validator: handleWhiteSpaceValidation,
  };
  const numberPattern = {
    validator: handleNumberValidation,
  };
  const positive = {
    validator: positiveNumberValidation,
  };
  const numberAndCharacter = {
    validator: numberAndCharacterValidation,
  };

  const percentage = {
    validator: percentageValidation,
  };

  const pattern =
    type === "email"
      ? [emailPattern]
      : type === "names"
      ? [whiteSpacePattern, numberAndCharacter]
      : type === "website"
      ? [webPattern]
      : type === "percentage"
      ? [percentage]
      : type === "number"
      ? props.allInteger
        ? [whiteSpacePattern, numberPattern]
        : [whiteSpacePattern, numberPattern, positive]
      : [whiteSpacePattern];

  const tempRule = [
    {
      required: required,
      message: message || `Please enter ${label}`,
    },
    ...pattern,
  ];

  const localRules =
    rules instanceof Array ? [...tempRule, ...rules] : tempRule;

  const searchProps = search
    ? {
        suffix: !loading ? (
          <SearchOutlined style={{ color: "rgba(14, 16, 26, 0.5)" }} />
        ) : (
          <LoadingOutlined style={{ color: "rgba(14, 16, 26, 0.5)" }} />
        ),
        onPressEnter: onSearch,
      }
    : {};

  const input = () => {
    if (!props.isMasked) {
      return (
        <Input
          initialValue
          ref={ref}
          onFocus={props.onFocus}
          type={props.inputType}
          min={props.inputType && !props.allInteger ? 0 : undefined}
          hidden={hidden || false}
          onBlur={onBlur}
          autoFocus={false}
          onClick={onClick}
          disabled={disabled || props.viewDetails}
          onChange={onChange}
          className={className}
          allowClear={allowClear}
          placeholder={placeholder || `Enter ${label}`}
          style={{
            height: 40,
            borderRadius: 4,
            color: disabled && !props.viewDetails ? "#777777" : "inherit",
            border: props.viewDetails ? "none" : "",
            background: props.viewDetails ? "white" : "",
            fontWeight: props.viewDetails ? "bold" : "",
            paddingLeft: props.viewDetails ? "0" : "12px",
            ...style,
          }}
          prefix={
            type === "email" ? (
              <MailOutlined
                style={{
                  color:
                    disabled && !props.viewDetails ? "#777777" : "lightgrey",
                }}
              />
            ) : type === "name" ? (
              <UserOutlined
                style={{
                  color:
                    disabled && !props.viewDetails ? "#777777" : "lightgrey",
                }}
              />
            ) : type === "password" ? (
              <LockOutlined
                style={{
                  color:
                    disabled && !props.viewDetails ? "#777777" : "lightgrey",
                }}
              />
            ) : type === "card" ? (
              <img src={CardIcon} />
            ) : null
          }
          {...searchProps}
          {...fieldProps}
        />
      );
    } else {
      return (
        <InputMask
          mask="(999) 999-9999"
          onBlur={props.onBlur}
          onChange={props.onChange}
          placeholder={placeholder || `Enter ${label}`}
        >
          {(inputProps) => (
            <input
              className="ant-input"
              style={{ height: 40, borderRadius: 4 }}
              {...inputProps}
            />
          )}
        </InputMask>
      );
    }
  };

  return (
    <FormItem {...props} localRules={localRules} labelStyle={labelStyle}>
      {!props.viewDetails || props.isField ? (
        input()
      ) : (
        <div
          style={{
            wordWrap: "break-word",
            wordBreak: "break-word",
          }}
        >
          <b>{props.initialValue || "-"}</b>
        </div>
      )}
    </FormItem>
  );
});

const AntSelect = React.forwardRef((props, ref) => {
  const {
    mode,
    style,
    array,
    value,
    rules,
    label,
    onBlur,
    onSelect,
    onClick,
    message,
    disabled,
    onChange,
    required,
    fieldProps,
    noClear,
    placeholder,
    description,
  } = props;

  const tempRule = [
    {
      required: required,
      message: message || `Please select ${label}`,
    },
  ];

  const localRules =
    rules instanceof Array ? [...tempRule, ...rules] : tempRule;

  return (
    <FormItem {...props} localRules={localRules}>
      {!props.viewDetails ||
      props.isField ||
      props.initialValue instanceof Array ? (
        <Select
          ref={ref}
          mode={mode}
          showSearch={!props.noSearch}
          onBlur={onBlur}
          autoFocus={false}
          onClick={onClick}
          disabled={disabled || props.viewDetails}
          onChange={onChange}
          onSelect={onSelect}
          optionFilterProp="children"
          className={props.viewDetails ? "custom-select" : props.className}
          filterOption={props.filterOption}
          allowClear={!noClear}
          notFoundContent={props.waitFor ? <Spin size="small" /> : "No data"}
          dropdownRender={props.dropdownRender}
          placeholder={placeholder || `Select ${label}`}
          style={{
            height: 40,
            borderRadius: 4,
            fontFamily: "Poppins-Medium",
            ...style,
          }}
          {...fieldProps}
        >
          {!isEmpty(array)
            ? isArray(array) &&
              array.map((item, index) => {
                return (
                  <Option key={index} value={item[value]}>
                    {item[description]}
                  </Option>
                );
              })
            : props.children}
        </Select>
      ) : (
        <div
          style={{
            wordWrap: "break-word",
            wordBreak: "break-word",
          }}
        >
          <b>
            {array.find((item, index) => item[value] === props.initialValue)?.[
              description
            ] || "-"}
          </b>
        </div>
      )}
    </FormItem>
  );
});

export const Search = (props) => {
  const {
    style,
    loading,
    onClick,
    options,
    onSearch,
    onChange,
    onSelect,
    className,
    inputStyle,
    fieldProps,
    placeholder,
    inputClassName,
  } = props;

  const searchProps = {
    prefix: <SearchOutlined style={{ color: "rgba(14, 16, 26, 0.5)" }} />,

    suffix: loading && (
      <LoadingOutlined style={{ color: "rgba(14, 16, 26, 0.5)" }} />
    ),
    onPressEnter: onSearch,
  };
  return (
    <AutoComplete
      allowClear
      onClick={onClick}
      backfill={true}
      options={options}
      onSearch={onSearch}
      onChange={onChange}
      onSelect={onSelect}
      className={className}
      style={{ ...style, width: "100%", color: "black" }}
      filterOption={(inputValue, option) =>
        option?.fullName?.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
      }
      {...fieldProps}
    >
      <Input
        className={inputClassName}
        placeholder={placeholder}
        style={{
          ...inputStyle,
          height: "32px",

          color: "#262626",
          ...inputStyle,
        }}
        {...searchProps}
      />
    </AutoComplete>
  );
};

export const TextArea = (props) => {
  const {
    rules,
    style,
    label,
    onBlur,
    onClick,
    message,
    minRows,
    maxRows,
    onChange,
    required,
    className,
    fieldProps,
    placeholder,
  } = props;

  const handleWhiteSpaceValidation = (rules, value) => {
    if (value) {
      if (value?.split("")?.[0] !== " ") {
        return Promise.resolve();
      } else {
        return Promise.reject(`${label} cannot start with space`);
      }
    } else {
      return Promise.resolve();
    }
  };

  const whiteSpacePattern = {
    validator: handleWhiteSpaceValidation,
  };

  const pattern = [whiteSpacePattern];

  const tempRule = [
    {
      required: required,
      message: message || `Please enter ${label}`,
    },
    ...pattern,
  ];

  const localRules =
    rules instanceof Array ? [...tempRule, ...rules] : tempRule;

  return (
    <FormItem {...props} localRules={localRules} textArea overflowHidden>
      <Input.TextArea
        onBlur={onBlur}
        onClick={onClick}
        autoFocus={false}
        autoComplete="off"
        maxLength={props.maxLength}
        onChange={onChange}
        disabled={props.disabled || props.viewDetails}
        className={className}
        autoSize={{ minRows: minRows || 8, maxRows: maxRows || 12 }}
        placeholder={placeholder || `Enter ${label}`}
        style={{
          color: "#262626",
          overflowX: "hidden",
          overflowY: "hidden",
          borderRadius: 4,
          border: props.viewDetails ? "none" : "",
          background: props.viewDetails ? "white" : "",
          fontWeight: props.viewDetails ? "bold" : "",
          paddingLeft: props.viewDetails ? "0" : "12px",

          ...style,
        }}
        {...fieldProps}
      />
    </FormItem>
  );
};

export const Password = (props) => {
  const {
    label,
    style,
    rules,
    onBlur,
    message,
    onClick,
    required,
    onChange,
    className,
    fieldProps,
    allowClear,
    placeholder,
  } = props;

  const lengthValidator = {
    validator: (rules, value) => {
      if (value?.length >= 8) {
        return Promise.resolve();
      } else {
        return Promise.reject(`Should be at least 8 characters long`);
      }
    },
  };

  const upperCaseValidator = {
    pattern: new RegExp("^(?=.*[A-Z]).+$"),
    message: "Should contain a uppercase alphabet",
  };

  const lowerCaseValidator = {
    pattern: new RegExp("^(?=.*[a-z]).+$"),
    message: "Should contain a lowercase alphabet",
  };

  const numberValidor = {
    pattern: new RegExp("^(?=.*[0-9]).+$"),
    message: "Should contain a number",
  };

  const specialChar = {
    pattern: new RegExp("^(?=.*[!@#$%^&*]).+$"),
    message: "Should contain a special character",
  };

  const tempRules = rules instanceof Array ? rules : [];
  const localRules =
    props.validate && required
      ? [
          ...tempRules,
          {
            required: required,
            message: message || "Please enter your password",
          },
          lowerCaseValidator,
          upperCaseValidator,
          numberValidor,
          specialChar,
          lengthValidator,
        ]
      : required
      ? [
          ...tempRules,
          {
            required: required,
            message: message || "Please enter your password",
          },
        ]
      : tempRules;

  return (
    <FormItem {...props} localRules={localRules}>
      <Input.Password
        allowClear={allowClear}
        onBlur={onBlur}
        autoFocus={false}
        onClick={onClick}
        onChange={onChange}
        className={className}
        placeholder={placeholder || `Enter ${label}`}
        autoComplete="new-password"
        onKeyPress={props.onKeyPress}
        style={{
          color: "#262626",
          height: 40,
          borderRadius: 4,

          ...style,
        }}
        prefix={<LockOutlined style={{ color: "lightgrey" }} />}
        {...fieldProps}
      />
    </FormItem>
  );
};

const confirmApprove = (e, callback) => {
  confirm({
    title: "Are you sure you want to approve?",
    icon: <CheckCircleOutlined style={{ color: "#10C469" }} />,
    okText: "Approve",
    cancelText: "Cancel",
    okButtonProps: {
      className: "confirm-approve-button ",
    },
    cancelButtonProps: {
      className: "cancel-button",
    },
    onOk() {
      callback && callback(e);
    },
  });
};

const confirmDisapprove = (e, callback) => {
  confirm({
    title: "Are you sure you want to dissapprove?",
    icon: <CloseCircleOutlined />,
    okText: "Disapprove",
    cancelText: "Cancel",
    okButtonProps: {
      className: "confirm-disapprove-button",
    },
    cancelButtonProps: {
      className: "cancel-button",
    },
    onOk() {
      callback && callback(e);
    },
  });
};

const confirmDeselect = (e, callback) => {
  confirm({
    title: "Are you sure you want to deselect all the items?",
    icon: <CloseCircleOutlined />,
    okText: "Yes",
    cancelText: "Cancel",
    okButtonProps: {
      className: "confirm-disapprove-button",
    },
    cancelButtonProps: {
      className: "cancel-button",
    },
    onOk() {
      callback && callback(e);
    },
  });
};

export const confirmDelete = (
  e,
  callback,
  onCancle,
  title,
  okText,
  okButtonClass
) => {
  confirm({
    title: title || "Are you sure you want to delete?",
    icon: <DeleteOutlined style={{ color: "red" }} />,
    okText: okText || "Delete",
    cancelText: "Cancel",
    okButtonProps: {
      className: okButtonClass || "confirm-delete-button",
    },
    cancelButtonProps: {
      className: "cancel-button",
    },
    onOk() {
      callback && callback(e);
    },
    onCancel() {
      onCancle && onCancle(e);
    },
  });
};

export const bioHazardModal = (e, callback) => {
  callback && callback(e);
};

const AntButton = (props) => {
  const { to, type, style, onClick, children, className, fieldProps, width } =
    props;
  const localType = props.attach ? "text" : type;
  const localClassName =
    localType === "cancel"
      ? "cancel-button"
      : localType === "text"
      ? "text-button"
      : localType === "danger"
      ? "danger"
      : localType === "success"
      ? "success"
      : localType === "none"
      ? ""
      : "primary-button";

  return (
    <FormItem local {...props}>
      <Link style={{ textDecoration: "none" }} to={to ? to : "#"}>
        <Button
          onClick={(e) => {
            !to && e.preventDefault();
            return props.isApproveConfirm
              ? confirmApprove(e, onClick)
              : props.isDissapproveConfirm
              ? confirmDisapprove(e, onClick)
              : props.isDeleteConfirm
              ? confirmDelete(e, onClick)
              : props.isDeselect
              ? confirmDeselect(e, onClick)
              : onClick && onClick(e);
          }}
          className={`centralize ${localClassName} ${className}`}
          loading={props.loading}
          style={{
            height: props.height || 40,
            borderRadius: 4,
            maxWidth: props.maxWidth,
            width: width || "100%",
            fontWeight: "Poppins-Medium",
            ...style,
          }}
          disabled={props.disabled}
          {...fieldProps}
        >
          {props.attach ? (
            <span style={{ fontSize: "16px" }}>
              <PaperClipOutlined /> Attach
            </span>
          ) : (
            children
          )}
        </Button>
      </Link>
    </FormItem>
  );
};

export const Notification = (title, message, type) => {
  notification.open({
    message: (
      <span style={{ color: "black", fontWeight: "bold", fontSize: 18 }}>
        {title}!
      </span>
    ),
    description: (
      <span style={{ color: "black", fontSize: 14 }}>{message}</span>
    ),
    className:
      type === "success"
        ? "notification-s"
        : type === "error"
        ? "notification-e"
        : "notification-w",
    icon:
      type === "success" ? (
        <CheckCircleOutlined
          style={{ color: "black", fontWeight: "bold", fontSize: 20 }}
        />
      ) : type === "error" ? (
        <CloseCircleOutlined />
      ) : (
        <ExclamationCircleOutlined
          style={{ color: "black", fontWeight: "bold", fontSize: 20 }}
        />
      ),
    placement: "bottomRight",
    style: {
      width: 380,
    },
  });
};

const AntCheckbox = (props) => {
  const { disabled, style, onClick, className } = props;
  const tempRule = [
    {
      required: props.required,
      message: props.message || `Please check ${props.label}`,
    },
  ];

  const localRules =
    props.rules instanceof Array ? [...tempRule, ...props.rules] : tempRule;
  return (
    <FormItem {...props} valuePropName="checked" localRules={localRules}>
      {props.viewDetails ? (
        <Checkbox
          onChange={props.onChange}
          disabled={disabled}
          onClick={onClick}
          style={style}
          className={className}
        />
      ) : (
        <div
          style={{
            textAlign: "center",
            wordWrap: "break-word",
            wordBreak: "break-word",
          }}
        >
          <Checkbox
            onChange={props.onChange}
            disabled={disabled}
            onClick={onClick}
            style={style}
            className={className}
          />
          <span
            style={{
              paddingLeft: "4px",
              fontFamily: "Poppins",
              fontWeight: 400,
            }}
          >
            {props.initialValue}
          </span>
        </div>
      )}
    </FormItem>
  );
};

export const Card = (props) => {
  return (
    <div>
      {props.backLabel && !props.renderHeadTitle ? (
        <div style={{ marginBottom: "20px" }}>
          <h3 style={{ fontWeight: "bold" }}>
            <Link className={"card-link"} to={props.backLink || "#"}>
              <ArrowLeftOutlined /> {props.backLabel}
            </Link>
          </h3>
        </div>
      ) : (
        props.renderHeadTitle && <>{props.renderHeadTitle()}</>
      )}
      <div
        onClick={props.onClick}
        className={`${props.className}`}
        style={{
          background: "white",
          width: props.width || "100%",

          border: props.borderless ? "none" : "1px solid lightgrey",
          borderRadius: props.borderRadius ? props?.borderRadius : "8px",
          padding: !props.sub ? "18px 20px" : "10px",
          overflow: props.overflow || "auto",
          ...props.style,
        }}
      >
        <Skeleton loading={props.loading} active>
          {props.renderHeader ? (
            <>{props.renderHeader()}</>
          ) : props.title && !props.sub ? (
            <h3
              style={{
                fontWeight: "bold",
                marginBottom: props.headingMargin || "20px",
              }}
            >
              {props.title}
            </h3>
          ) : (
            <h4
              style={{
                fontWeight: "bold",
                marginBottom: props.headingMargin || "10px",
              }}
            >
              {props.title}
            </h4>
          )}
          {props.children}
        </Skeleton>
      </div>
    </div>
  );
};

const AntDatePicker = (props) => {
  const tempRule = [
    {
      required: props.required,
      message: props.message || `Please select ${props.label}`,
    },
  ];

  const localRules =
    props.rules instanceof Array ? [...tempRule, ...props.rules] : tempRule;
  const localInitialValue = props.initialDate
    ? moment(props.initialDate)
    : null;
  const [openDate, setopenDate] = useState(false);

  return (
    <FormItem
      {...props}
      initialValue={localInitialValue}
      localRules={localRules}
    >
      {!props.viewDetails ? (
        <DatePicker
          {...props}
          valueP
          disabled={props.disabled || props.viewDetails}
          placeholder={"YYYY-MM-DD"}
          format={
            openDate ? ["DDMMYYYY", "DD-MM-YYYY", "DD/MM/YYYY"] : "YYYY-MM-DD"
          }
          onOpenChange={(status) => {
            setopenDate(status);
          }}
          onChange={props.onChange}
          className={props.className}
          style={{
            height: 40,
            borderRadius: 4,
            width: "100%",
            border: props.viewDetails ? "none" : "",
            background: props.viewDetails ? "white" : "",
            color: props.disabled && !props.viewDetails ? "#777777" : "#262626",
            fontFamily: "Poppins-Medium",
            ...props.style,
          }}
        />
      ) : (
        <div
          style={{
            wordWrap: "break-word",
            wordBreak: "break-word",
          }}
        >
          <b>
            {props.initialDate
              ? moment(props.initialDate)?.format("DD-MM-YYYY")
              : "-"}
          </b>
        </div>
      )}
    </FormItem>
  );
};

const AntTimePicker = (props) => {
  const tempRule = [
    {
      required: props.required,
      message: props.message || `Please select ${props.label}`,
    },
  ];

  const localRules =
    props.rules instanceof Array ? [...tempRule, ...props.rules] : tempRule;
  const localInitialValue = props.initialDate
    ? moment(props.initialDate)
    : null;
  const [openDate, setopenDate] = useState(false);

  return (
    <FormItem
      {...props}
      initialValue={localInitialValue}
      localRules={localRules}
    >
      {!props.viewDetails ? (
        <TimePicker
          {...props}
          valueP
          disabled={props.disabled || props.viewDetails}
          // placeholder={"DD-MM-YYYY"}
          use12Hours={false}
          format="h:mm"
          onOpenChange={(status) => {
            setopenDate(status);
          }}
          onChange={props.onChange}
          className={props.className}
          style={{
            height: 40,
            borderRadius: 4,
            width: "100%",
            border: props.viewDetails ? "none" : "",
            background: props.viewDetails ? "white" : "",
            fontWeight: props.viewDetails ? "bold" : "",
            color: props.disabled && !props.viewDetails ? "#777777" : "#262626",
            ...props.style,
          }}
        />
      ) : (
        <div
          style={{
            wordWrap: "break-word",
            wordBreak: "break-word",
          }}
        >
          <b>
            {props.initialDate
              ? moment(props.initialDate)?.format("DD-MM-YYYY")
              : "-"}
          </b>
        </div>
      )}
    </FormItem>
  );
};

export const AddButton = (props) => {
  return (
    <AntButton
      style={{ paddingTop: "5px" }}
      {...props}
      maxWidth={!props.width ? (!props.full ? "40px" : "200px") : props.width}
      type="text"
    >
      <PlusOutlined />
      {props.full ? "Add" : props.children}
    </AntButton>
  );
};

export { AntInput as Input };
export { AntSelect as Select };
export { AntButton as Button };
export { AntCheckbox as Checkbox };
export { AntDatePicker as DatePicker };
export { AntTimePicker as TimePicker };
