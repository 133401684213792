import React, { Suspense } from "react";

import { Redirect, Route } from "react-router-dom";
import Loading from "../components/Loading";
import { isAuthenticated, isSignUp } from "../utils/authUtil";

/**
 * Redirect back to a particular route if the use is already aunthenticated
 * and try to access login page.
 * @returns react route with redered component
 */
const RestrictRoute = ({ component: Component, layout: Layout, ...rest }) => {
  return rest.path === "" || rest.path === "/" ? (
    <Route
      {...rest}
      render={(props) => (
        <Redirect
          to={{
            pathname: "/auth/sign-up",
            state: { from: props.location },
          }}
        />
      )}
    />
  ) : (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated() && !isSignUp() ? (
          <Redirect
            to={{
              pathname: "/home",
              state: { from: props.location },
            }}
          />
        ) : (
          <Layout>
            <Suspense fallback={<Loading />}>
              <Component {...props} />
            </Suspense>
          </Layout>
        )
      }
    />
  );
};

export default RestrictRoute;
