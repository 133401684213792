import React, { useState } from "react";
import { Layout } from "antd";
import "./index.css";
import AppHeader from "./Header";
import { useSize } from "../../windowSize";

import { submitFeedBackInformation } from "../../services/commonServices";
import SideNav from "./SideNav";

const AppLayout = ({ children }) => {
  const [headerTitle, setHeaderTitle] = useState("Home");
  const { innerWidth, collapsed, setCollapsed } = useSize();
  const [navCollapsed, setNavCollapsed] = useState(collapsed);
  const { Content } = Layout;

  return (
    <Layout style={{ height: "100vh" }}>
      <SideNav
        setNavCollapsed={setNavCollapsed}
        setHeaderTitle={setHeaderTitle}
        setCollapsed={setCollapsed}
        collapsed={collapsed}
      />
      <Layout>
        <AppHeader navCollapsed={navCollapsed} headerTitle={headerTitle} />
        <Content
          style={{
            overflow: "auto",
            padding: innerWidth > 685 ? "30px" : "30px 30px 30px 106px",
            background: "#F0F2F5",
          }}
        >
          {!collapsed && innerWidth < 685 && (
            <div
              onClick={() => {
                setCollapsed(true);
              }}
              style={{
                position: "absolute",
                background: "none",
                height: "100%",
                width: "100%",
                top: 0,
                left: 0,
                zIndex: 2,
              }}
            ></div>
          )}
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};

export default AppLayout;
