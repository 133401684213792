import moment from "moment";

import { loadState, removeState } from "./storageUtil";
import {
  GENESIS_USER_SIGNUP,
  GENESIS_USER_TOKEN,
} from "./constants/appConfigs";
// import { JWT_TOKEN } from '../constants/appConfig';

export let isTokenExpired = () => {
  const expiresIn = loadState(GENESIS_USER_TOKEN)
    ?.[`.expires`]?.split(",")
    .join(" ");
  if (moment(expiresIn).valueOf() > Date.now()) {
    // Checking if token is expired.
    return true;
  } else {
    removeState(GENESIS_USER_TOKEN);
    localStorage.removeItem("GenesisNavigation");
    return false;
  }
};

export let isAuthenticated = () => {
  return loadState(GENESIS_USER_TOKEN);
};

export let isSignUp = () => {
  return loadState(GENESIS_USER_SIGNUP);
};
