import React, { Suspense } from "react";
import { Route, Redirect } from "react-router-dom";

import LoadingComponent from "../components/Loading";

/**
 * Set default route to login page if the user is not authenticated and is trying to
 * access a public page.
 * @returns react route wiht rendered component
 */
const PublicRoute = ({ component: Component, layout: Layout, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (rest.path === "/" || rest.path === "") {
        return (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        );
      }

      // authorised so return component
      return (
        <Suspense fallback={<LoadingComponent />}>
          <Component {...props} />
        </Suspense>
      );
    }}
  />
);

export default PublicRoute;
