import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { updateUser } from "../../services/userService";

const Callback = () => {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const searchParams = location?.hash;
    // const authorizationCode = searchParams.get("token");
    const accessToken = searchParams?.split("access_token=")?.[1];
    if (accessToken) {
      updateUser({ spotifyToken: accessToken });
      history.push("/social");
    }

    // Perform a server-side API call to exchange the authorization code for an access token
    // You can use libraries like axios or fetch to make the API call
    // Example API call:
    /*
    axios.post('https://accounts.spotify.com/api/token', {
      grant_type: 'authorization_code',
      code: authorizationCode,
      redirect_uri: redirectUri,
      client_id: clientId,
      client_secret: clientSecret,
    }).then(response => {
      const accessToken = response.data.access_token;
      // Store the access token in your app's state or local storage
      // Redirect to your main application route
    }).catch(error => {
      // Handle the error
    });
    */

    // Remember to handle error scenarios as well
  }, [location]);

  return (
    <div>
      <h3>Processing...</h3>
      {/* You can show a loading spinner or a message while the authorization process is in progress */}
    </div>
  );
};

export default Callback;
