import React from "react";

import homeIcon from "../../assets/images/icon-image/DashboardIcon.png";
import eventIcon from "../../assets/images/icon-image/EventIcon.svg";
import socialIcon from "../../assets/images/icon-image/SocialIcon.svg";
import badgeIcon from "../../assets/images/icon-image/BadgeIcon.svg";
import goalIcon from "../../assets/images/icon-image/GoalIcon.svg";
import settingsIcon from "../../assets/images/icon-image/SettingsIcon.svg";
import logoutIcon from "../../assets/images/icon-image/LogoutIcon.svg";
import servicesIcon from "../../assets/images/icon-image/ServicesIcon.svg";

const navMenu = [
  {
    to: "/home",
    icon: homeIcon,
    activeIcon: homeIcon,
    id: "/home",
    label: "Dashboard",
    right: "Dashboard",
  },
  {
    to: "/event",
    icon: eventIcon,
    activeIcon: eventIcon,
    id: "/event",
    label: "Event/Task",
    right: "Event",
  },
  {
    to: "/social",
    icon: socialIcon,
    activeIcon: socialIcon,
    id: "/social",
    label: "Social & Streaming",
    right: "Social",
  },
  {
    to: "/badge",
    icon: badgeIcon,
    activeIcon: badgeIcon,
    id: "/badge",
    label: "Badges",
    right: "Badges",
  },
  {
    to: "/goal",
    icon: goalIcon,
    activeIcon: goalIcon,
    id: "/goal",
    label: "Goal",
    right: "Goal",
  },
  {
    to: "/settings",
    icon: settingsIcon,
    activeIcon: settingsIcon,
    id: "/settings",
    label: "Settings",
    right: "Settings",
  },
  {
    to: "/services",
    icon: servicesIcon,
    activeIcon: servicesIcon,
    id: "/services",
    label: "Services",
    right: "Services",
  },
  {
    to: "/booking-history",
    icon: eventIcon,
    activeIcon: eventIcon,
    id: "/booking-history",
    label: "Booking History",
    right: "Booking History",
  },
  // {
  //   to: "/auth",
  //   icon: logoutIcon,
  //   activeIcon: logoutIcon,
  //   id: "/logout",
  //   label: "Logout",
  //   right: "Logout",
  // },
];
export default navMenu;
