import React from "react";
import Layout from "../../components/Layout";
import AuthLayout from "../../components/AuthLayout";

/**
 * Dynamically import component by there folder name
 * @param {string} importString
 * @returns Lazy loaded component
 */
const asyncComponent = (importString) => {
  return React.lazy(() => import(`../${importString}`));
};

/**
 * First element is layout component that wraps privated component
 * Second element is layout compoent that wraps authantication component
 * Third element is list of components with there respective routes and nature of there authentication.
 */
const AsyncComponent = [
  Layout,
  AuthLayout,
  [
    [asyncComponent("Auth"), ["auth", "", "/"], "restricted"],
    [
      asyncComponent("Onboarding"),
      ["onboarding", "", "onboarding"],
      "restricted",
    ],
    [
      asyncComponent("ProfilePicture"),
      ["profile-image", "", "profile-image"],
      "public",
    ],
    [asyncComponent("MakePayment"), ["payment", "", "payment"], "restricted"],
    [asyncComponent("Dashboard"), ["home"], "private"],
    [asyncComponent("Event"), ["event"], "private"],
    [asyncComponent("Social"), ["social"], "private"],
    [asyncComponent("Callback"), ["callback"], "private"],
    [asyncComponent("Badges"), ["badge"], "private"],
    [asyncComponent("Settings"), ["settings"], "private"],
    [asyncComponent("Services"), ["services"], "private"],
    [asyncComponent("BookingHistory"), ["booking-history"], "private"],
  ],
];

export default AsyncComponent;
