export const REDUCER_CLEAN_REQUEST = "REDUCER_CLEAN_REQUEST";
export const PACKAGE_CLEAN_REQUEST = "PACKAGE_CLEAN_REQUEST";

export const SIGN_IN = "SIGN_IN";
export const SIGN_IN_SUCCESS = "SIGN_IN_SUCCESS";
export const SIGN_IN_FAILURE = "SIGN_IN_FAILURE";
export const SIGN_OUT = "SIGN   _OUT";

export const SIGN_UP = "SIGN_UP";
export const SIGN_UP_SUCCESS = "SIGN_UP_SUCCESS";
export const SIGN_UP_FAILURE = "SIGN_UP_FAILURE";

export const ONBOARDING = "ONBOARDING";
export const ONBOARDING_SUCCESS = "ONBOARDING_SUCCESS";
export const ONBOARDING_FAILURE = "ONBOARDING_FAILURE";

export const GOOGLE_SIGN_IN = "GOOGLE_SIGN_IN";
export const GOOGLE_SIGN_IN_SUCCESS = "GOOGLE_SIGN_IN_SUCCESS";
export const GOOGLE_SIGN_IN_FAILURE = "GOOGLE_SIGN_IN_FAILURE";

export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";

export const VERIFY_OTP = "VERIFY_OTP";
export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS";
export const VERIFY_OTP_FAILURE = "VERIFY_OTP_FAILURE";

export const RESEND_OTP = "RESEND_OTP";
export const RESEND_OTP_SUCCESS = "RESEND_OTP_SUCCESS";
export const RESEND_OTP_FAILURE = "RESEND_OTP_FAILURE";

export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";

export const UPLOAD_IMAGE = "UPLOAD_IMAGE";
export const UPLOAD_IMAGE_SUCCESS = "UPLOAD_IMAGE_SUCCESS";
export const UPLOAD_IMAGE_FAILURE = "UPLOAD_IMAGE_FAILURE";

export const SPOTIFY_AUTH = "SPOTIFY_AUTH";
export const SPOTIFY_AUTH_SUCCESS = "SPOTIFY_AUTH_SUCCESS";
export const SPOTIFY_AUTH_FAILURE = "SPOTIFY_AUTH_FAILURE";

export const CREATE_EVENT = "CREATE_EVENT";
export const CREATE_EVENT_SUCCESS = "CREATE_EVENT_SUCCESS";
export const CREATE_EVENT_FAILURE = "CREATE_EVENT_FAILURE";

export const GET_EVENT = "GET_EVENT";
export const GET_EVENT_SUCCESS = "GET_EVENT_SUCCESS";
export const GET_EVENT_FAILURE = "GET_EVENT_FAILURE";

export const GET_TASK = "GET_TASK";
export const GET_TASK_SUCCESS = "GET_TASK_SUCCESS";
export const GET_TASK_FAILURE = "GET_TASK_FAILURE";

export const GET_CATEGORIES = "GET_CATEGORIES";
export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS";
export const GET_CATEGORIES_FAILURE = "GET_CATEGORIES_FAILURE";

export const GET_PROJECTS = "GET_PROJECTS";
export const GET_PROJECTS_SUCCESS = "GET_PROJECTS_SUCCESS";
export const GET_PROJECTS_FAILURE = "GET_PROJECTS_FAILURE";

export const POST_INDIVIDUAL_TASK = "POST_INDIVIDUAL_TASK";
export const POST_INDIVIDUAL_TASK_SUCCESS = "POST_INDIVIDUAL_TASK_SUCCESS";
export const POST_INDIVIDUAL_TASK_FAILURE = "POST_INDIVIDUAL_TASK_FAILURE";

export const POST_PROJECT = "POST_PROJECT";
export const POST_PROJECT_SUCCESS = "POST_PROJECT_SUCCESS";
export const POST_PROJECT_FAILURE = "POST_PROJECT_FAILURE";

export const GET_DASHBOARD = "GET_DASHBOARD";
export const GET_DASHBOARD_SUCCESS = "GET_DASHBOARD_SUCCESS";
export const GET_DASHBOARD_FAILURE = "GET_DASHBOARD_FAILURE";

export const GET_ALL_SERVICES = "GET_ALL_SERVICES";
export const GET_ALL_SERVICES_SUCCESS = "GET_ALL_SERVICES_SUCCESS";
export const GET_ALL_SERVICES_FAILURE = "GET_ALL_SERVICES_FAILURE";

export const GET_PROFESSIONAL_BY_SERVICES_ID =
  "GET_PROFESSIONAL_BY_SERVICES_ID";
export const GET_PROFESSIONAL_BY_SERVICES_ID_SUCCESS =
  "GET_PROFESSIONAL_BY_SERVICES_ID_SUCCESS";
export const GET_PROFESSIONAL_BY_SERVICES_ID_FAILURE =
  "GET_PROFESSIONAL_BY_SERVICES_ID_FAILURE";

export const GET_BOOKING_SLOTS_BY_SERVICE_ID =
  "GET_BOOKING_SLOTS_BY_SERVICE_ID";
export const GET_BOOKING_SLOTS_BY_SERVICE_ID_SUCCESS =
  "GET_BOOKING_SLOTS_BY_SERVICE_ID_SUCCESS";
export const GET_BOOKING_SLOTS_BY_SERVICE_ID_FAILURE =
  "GET_BOOKING_SLOTS_BY_SERVICE_ID_FAILURE";

export const POST_BOOKING_SLOTS_BY_SERVICE_ID =
  "POST_BOOKING_SLOTS_BY_SERVICE_ID";
export const POST_BOOKING_SLOTS_BY_SERVICE_ID_SUCCESS =
  "POST_BOOKING_SLOTS_BY_SERVICE_ID_SUCCESS";
export const POST_BOOKING_SLOTS_BY_SERVICE_ID_FAILURE =
  "POST_BOOKING_SLOTS_BY_SERVICE_ID_FAILURE";

export const GET_BOOKING_HISTORY = "GET_BOOKING_HISTORY";
export const GET_BOOKING_HISTORY_SUCCESS = "GET_BOOKING_HISTORY_SUCCESS";
export const GET_BOOKING_HISTORY_FAILURE = "GET_BOOKING_HISTORY_FAILURE";
