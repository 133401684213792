import { Api } from "./apiUtil";
import qs from "qs";

/**
 *
 * @param {*} endpoint
 * @param {*} params
 * @param {*} contentType
 * @returns
 */
export function fetch(endpoint, params = {}, contentType, googleToken) {
  return Api(contentType, params, googleToken).get(`/${endpoint}`, { params });
}

/**
 *
 * @param {*} endpoint
 * @param {*} data
 * @param {*} contentType
 * @param {*} textDetection
 * @returns
 */
export function store(endpoint, data, contentType, textDetection) {
  const formData =
    contentType === "multipart/form-data"
      ? data
      : contentType === "application/json"
      ? JSON.stringify(data)
      : qs.stringify(data);
  return Api(contentType, {}, textDetection).post(`/${endpoint}`, formData);
}

/**
 *
 * @param {*} endpoint
 * @param {*} data
 * @param {*} contentType
 * @param {*} noAuthorize
 * @returns
 */
export function update(endpoint, data, contentType, noAuthorize) {
  const formData = contentType === "multipart/form-data" ? data : data;
  return Api(contentType, {}, noAuthorize).put(`/${endpoint}`, formData);
}

/**
 *
 * @param {*} endpoint
 * @param {*} id
 * @returns
 */
export function destroy(endpoint, id) {
  const apiEndpoint = id ? `/${endpoint}/${id}` : endpoint;
  return Api().delete(apiEndpoint);
}

/**
 *
 * @param {*} type
 * @returns
 */
const request = (type) => {
  return {
    type: type,
  };
};

/**
 *
 * @param {*} type
 * @param {*} data
 * @returns
 */
const requestSuccess = (type, data) => {
  return {
    type: type,
    data,
  };
};

/**
 *
 * @param {*} type
 * @param {*} data
 * @returns
 */
const requestFailure = (type, data) => {
  return {
    type: type,
    data,
  };
};

/**
 *
 * @param {*} requestActionType
 * @param {*} requestSuccessActionType
 * @param {*} requestFailureActionType
 * @param {*} api
 * @param {*} params
 * @param {*} contentType
 * @returns
 */
export const get = (
  requestActionType,
  requestSuccessActionType,
  requestFailureActionType,
  api,
  params,
  contentType,
  googleToken
) => {
  return (dispatch) => {
    dispatch(request(requestActionType));
    return fetch(api, params, contentType, googleToken)
      .then((res) => {
        dispatch(requestSuccess(requestSuccessActionType, res?.data));
        return Promise.resolve(res?.data);
      })
      .catch((err) => {
        dispatch(requestFailure(requestFailureActionType, err?.data));
        return Promise.reject(err?.data);
      });
  };
};

/**
 *
 * @param {*} requestActionType
 * @param {*} requestSuccessActionType
 * @param {*} requestFailureActionType
 * @param {*} api
 * @param {*} params
 * @param {*} contentType
 * @returns
 */
export const postData = (
  requestActionType,
  requestSuccessActionType,
  requestFailureActionType,
  api,
  params,
  contentType
) => {
  return (dispatch) => {
    dispatch(request(requestActionType));

    return post(api, params, contentType)
      .then((res) => {
        if (res?.status === "success") {
          dispatch(requestSuccess(requestSuccessActionType, res?.data));
          return Promise.resolve(res);
        } else {
          dispatch(requestFailure(requestFailureActionType, res?.message));
          return Promise.reject(res);
        }
      })
      .catch((err) => {
        dispatch(requestFailure(requestFailureActionType, err?.data));
        return Promise.reject(err);
      });
  };
};

/**
 *
 * @param {*} api
 * @param {*} params
 * @param {*} contentType
 * @param {*} noAuthorize
 * @returns
 */
export const post = async (api, params, contentType, noAuthorize) => {
  return await store(api, params, contentType, noAuthorize)
    .then((res) => {
      return Promise.resolve(res?.data);
    })
    .catch((err) => {
      return Promise.reject(err?.data);
    });
};

/**
 *
 * @param {*} api
 * @param {*} params
 * @param {*} contentType
 * @param {*} noAuthorize
 * @returns
 */
export const put = async (api, params, contentType, noAuthorize) => {
  return await update(api, params, contentType, noAuthorize)
    .then((res) => {
      return Promise.resolve(res?.data);
    })
    .catch((err) => {
      return Promise.reject(err?.data);
    });
};

/**
 *
 * @param {*} api
 * @param {*} id
 * @returns
 */
export const deleteData = async (api, id) => {
  return await destroy(api, id)
    .then((res) => {
      return Promise.resolve(res?.data);
    })
    .catch((err) => {
      return Promise.reject(err?.data);
    });
};
