import React, { Suspense } from "react";
import { Route, Redirect } from "react-router-dom";

import { isAuthenticated } from "../utils/authUtil";
import LoadingComponent from "../components/Loading";

/**
 * Redirect to particular routes if the use in not authenticated
 *
 * @returns react-route with rendered component
 */
const PrivateRoute = ({ component: Component, layout: Layout, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isAuthenticated()) {
          return (
            <Redirect
              to={{
                pathname: "/auth/login",
                state: { from: props.location },
              }}
            />
          );
        }
        return (
          <Layout>
            <Suspense fallback={<LoadingComponent />}>
              <Component {...props} />
            </Suspense>
          </Layout>
        );
      }}
    />
  );
};

export default PrivateRoute;
